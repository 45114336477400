import { DOTS_PROJECTOR, EMF_5, Evidence, FREEZING_TEMPS, GHOST_ORB, GHOST_WRITING, SPIRIT_BOX, ULTRAVIOLET } from "./Evidence";

export interface GhostType {
    /**
     * Ordinal indicating the order of the ghost type in the in-game journal.
     */
    readonly journalOrder: number

    /**
     * Name of the ghost type.
     */
    readonly name: string

    /**
     * List of evidence for the given ghost type.
     */
    readonly evidence: Evidence[]

    /**
     * Description of the ghost.
     */
    readonly description: string

    /**
     * Description of the ghosts strength, if applicable.
     */
    readonly strength?: string

    /**
     * Description of the ghosts weakness, if applicable.
     */
    readonly weakness?: string
}

export const GHOST_TYPES: GhostType[] = [
    {
        name: "Spirit",
        journalOrder: 0,
        evidence: [
            EMF_5,
            GHOST_WRITING,
            SPIRIT_BOX,
        ],
        description: "Spirits are very common ghosts. They are very powerful, but passive, only attacking when they need to. They defend their place of death to the utmost degree, killing anyone that is caught overstaying their welcome.",
        weakness: "A Spirit can be temporarily stopped by burning Smudge Sticks near them.",
    },
    {
        name: "Wraith",
        journalOrder: 1,
        evidence: [
            EMF_5,
            DOTS_PROJECTOR,
            SPIRIT_BOX,
        ],
        description: "Wraiths are one of the most dangerous ghosts you will find. It is also the only known ghost that has the ability of flight and has sometimes been known to travel through walls.",
        weakness: "Wraiths have a toxic reaction to Salt.",
        strength: "Wraiths almost never touch the ground, meaning it can't be tracked by footsteps",
    },
    {
        name: "Phantom",
        journalOrder: 2,
        evidence: [
            DOTS_PROJECTOR,
            ULTRAVIOLET,
            SPIRIT_BOX,
        ],
        description: "A Phantom is a ghost that can possess the living, inducing fear into those around it. They are most commonly summoned from Ouija Boards.",
        weakness: "Taking a photo of the Phantom will make it temporarily disappear.",
        strength: "Looking at a Phantom will drop your sanity considerably faster.",
    },
    {
        name: "Poltergeist",
        journalOrder: 3,
        evidence: [
            ULTRAVIOLET,
            GHOST_WRITING,
            SPIRIT_BOX,
        ],
        description: "One of the most famous ghosts, the Poltergeist. Known to manipulate objects around it to spread fear into its victims.",
        weakness: "With nothing to throw, Poltergeists become powerless.",
        strength: "Poltergeists can throw multiple objects at once, and with great force.",
    },
    {
        name: "Banshee",
        journalOrder: 4,
        evidence: [
            ULTRAVIOLET,
            GHOST_ORB,
            DOTS_PROJECTOR,
        ],
        description: "The singing siren, known for attracting its victims through song. It has been known to single out its prey before making a killing blow.",
        weakness: "Banshee's can sometimes be heard screaming with a parabolic microphone.",
        strength: "Banshee's will weaken their target before striking.",
    },
    {
        name: "Jinn",
        journalOrder: 5,
        evidence: [
            EMF_5,
            ULTRAVIOLET,
            FREEZING_TEMPS,
        ],
        description: "A Jinn is a territorial ghost that will attack when threatened. It has also been known to be able to travel at significant speed.",
        weakness: "Turning off the location's power source will prevent the Jinn from using its ability.",
        strength: "A Jinn will travel at a faster speed if its victim is far away.",
    },
    {
        name: "Mare",
        journalOrder: 6,
        evidence: [
            GHOST_ORB,
            GHOST_WRITING,
            SPIRIT_BOX,
        ],
        description: "A Mare is the source of all nightmares, making it most powerful in the dark.",
        weakness: "Turning the lights on around the Mare will lower its chance to attack.",
        strength: "A Mare will have an increased chance to attack in the dark.",
    },
    {
        name: "Revenant",
        journalOrder: 7,
        evidence: [
            GHOST_ORB,
            GHOST_WRITING,
            FREEZING_TEMPS,
        ],
        description: "A Revenant is a violent ghost that will attack indiscriminately. Their speed can be deceiving, as they are slow will dormant; however, as soon as they hunt they can move incredibly fast.",
        weakness: "A Revenant will travel at a significantly faster speed when hunting their prey.",
        strength: "Hiding from the Revenant will cause it to move very slowly.",
    },
    {
        name: "Shade",
        journalOrder: 8,
        evidence: [
            EMF_5,
            GHOST_WRITING,
            FREEZING_TEMPS,
        ],
        description: "A Shade is known to be very shy. There is evidence to suggest that a Shade will stop all paranomrmal activity if there are multiple people nerby.",
        weakness: "The ghost will not enter a hunt if there are multiple people nearby.",
        strength: "Shades are much harder to find.",
    },
    {
        name: "Demon",
        journalOrder: 9,
        evidence: [
            ULTRAVIOLET,
            GHOST_WRITING,
            FREEZING_TEMPS,
        ],
        description: "Demons are the most aggressive ghosts we've ever encountered. Known to attack without reason, they seem to enjoy the thrill of the hunt.",
        weakness: "Demons fear the Crucifix and will be less aggressive near one.",
        strength: "Demons will initiate hunts more often than other ghosts.",
    },
    {
        name: "Yurei",
        journalOrder: 10,
        evidence: [
            GHOST_ORB,
            FREEZING_TEMPS,
            DOTS_PROJECTOR,
        ],
        description: "A Yurei is a ghost that has returned to the physical world, usually for the purpose of revenge or hatred.",
        weakness: "Smudging the Yurei's place of death will trap it temporarily, reducing how much it wanders.",
        strength: "Yureis have been known to have a stronger effect on people's sanity.",
    },
    {
        name: "Oni",
        journalOrder: 11,
        evidence: [
            EMF_5,
            FREEZING_TEMPS,
            DOTS_PROJECTOR,
        ],
        description: "Onis love to scare their victims as much as possible before attacking. They are often seen in their physical form, guarding their place of death.",
        weakness: "Oni disappear less often while hunting their prey.",
        strength: "Oni are much more active whilst people are nearby and will drain their sanity faster when manifesting.",
    },
    {
        name: "Yokai",
        journalOrder: 12,
        evidence: [
            GHOST_ORB,
            DOTS_PROJECTOR,
            SPIRIT_BOX,
        ],
        description: "Yokai are common ghosts that are attracted to human voices. They can usually be found haunting family homes.",
        weakness: "When hunting, a Yokai can only hear voices close to it.",
        strength: "Talking near a Yokai will anger it, increasing the chance of an attack.",
    },
    {
        name: "Hantu",
        journalOrder: 13,
        evidence: [
            GHOST_ORB,
            ULTRAVIOLET,
            FREEZING_TEMPS,
        ],
        description: "A Hantu is a rare ghost that thrives in the coldest climates. The cold seems to make them more aggressive and empowered.",
        weakness: "Hantus move slower in warmer areas.",
        strength: "Lower temperatures allow the Hantu to move at faster speeds.",
    },
    {
        name: "Goryo",
        journalOrder: 14,
        evidence: [
            EMF_5,
            ULTRAVIOLET,
            DOTS_PROJECTOR,
        ],
        description: "When a Goryo passes through a D.O.T.S. projector, using a video camera is the only way to see it.",
        weakness: "They are rarely seen far from their place of death.",
        strength: "A Goryo will usually only show itself on camera if there are no people nearby.",
    },
    {
        name: "Myling",
        journalOrder: 15,
        evidence: [
            EMF_5,
            GHOST_WRITING,
            ULTRAVIOLET,
        ],
        description: "A Myling is a very vocal and active ghost. They are rumoured to be quiet when hunting their prey.",
        weakness: "Mylings more frequently make paranormal sounds.",
        strength: "A Myling is known to be quieter when hunting.",
    },
    {
        name: "Onryo",
        journalOrder: 16,
        evidence: [
            GHOST_ORB,
            FREEZING_TEMPS,
            SPIRIT_BOX,
        ],
        description: "The Onryo is often referred to as \"The Wrathful Spirit\". It steals souls from dying victims' bodies to seek revenge. This ghost has been known to fear any form of fire, and will do anything to be far from it.",
        weakness: "When threatened, this ghost will be less likely to hunt.",
        strength: "Extinguishing a flame can cause an Onryo to attack.",
    },
    {
        name: "The Twins",
        journalOrder: 17,
        evidence: [
            EMF_5,
            FREEZING_TEMPS,
            SPIRIT_BOX,
        ],
        description: "These ghosts have been reported to mimic each other's actions. They alternate their attacks to confuse their prey.",
        weakness: "The Twins will often interact with the environment at the same time.",
        strength: "Either Twin can be angered and initiate an attack on their prey.",
    },
    {
        name: "Raiju",
        journalOrder: 18,
        evidence: [
            EMF_5,
            GHOST_ORB,
            DOTS_PROJECTOR,
        ],
        description: "A Raiju is a demon that thrives on electrical current. While generally calm, they can become agitated when overhwlemed with power.",
        weakness: "Raiju are constantly disrupting electronic equipment when attacking, making it easier to track.",
        strength: "A Raiju can siphon power from nearby electrical devices, making it move faster.",
    },
    {
        name: "Obake",
        journalOrder: 19,
        evidence: [
            EMF_5,
            ULTRAVIOLET,
            GHOST_ORB,
        ],
        description: "Obake are terrifying shape-shifters, capable of taking on many forms. They have been seen taking on humanoid shapes to attract their prey.",
        weakness: "Sometimes this ghost will shapeshift, leaving behind unique ",
        strength: "When interacting with the environment, an Obake will rarely leave a trace.",
    },
    {
        name: "The Mimic",
        journalOrder: 20,
        evidence: [
            ULTRAVIOLET,
            FREEZING_TEMPS,
            SPIRIT_BOX,
        ],
        description: "The Mimic is an elusive, mysterious, copycat ghost that mirrors traits and behaviours from others, including other ghost types.",
        weakness: "Several reports have noted ghost orb sightings near mimics.",
        strength: "We're unsure what this ghost is capable of. Be careful.",
    },
    {
        name: "Moroi",
        journalOrder: 21,
        evidence: [
            GHOST_WRITING,
            SPIRIT_BOX,
            FREEZING_TEMPS,
        ],
        description: "Moroi have risen from the grave to drain energy from the living. They have been known to place curses on their victims, curable only by antidotes or moving very far away.",
        weakness: "Moroi suffer from hyperosmia, weakning them for longer periods.",
        strength: "The weaker their victims, the stronger the Moroi becomes.",
    },
    {
        name: "Deogen",
        journalOrder: 22,
        evidence: [
            SPIRIT_BOX,
            GHOST_WRITING,
            DOTS_PROJECTOR,
        ],
        description: "Sometimes surrounded by an endless fog, Deogen have been eluding ghost hunters for years. These ghosts have been reported to find even the most hidden prey, before stalking them into exhaustion.",
        weakness: "Deogen require a lot of energy to form and will move very slowly when approaching its victim.",
        strength: "Deogen constantly sense the living. You can run but you can't hide.",
    },
    {
        name: "Thaye",
        journalOrder: 23,
        evidence: [
            GHOST_ORB,
            GHOST_WRITING,
            DOTS_PROJECTOR,
        ],
        description: "Thaye have been known to rapidly age over time, even in the afterlife. From what we've learned, they seem to deteriorate faster while within the presence of the living.",
        weakness: "Thaye will weaken over time, making them weaker, slower and less aggressive.",
        strength: "Upon entering the location, Thaye will become active, defensive and agile.",
    },
]
