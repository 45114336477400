import { useMemo, useState } from "react";
import { GHOST_TYPES, GhostType } from "../Ghost";
import { EvidenceStatus, EvidenceStatuses } from "./useEvidence";
import { SearchParams } from "./useSearch";
import { keywordSearch } from "../search";

export enum GhostSort {
    Lexicographic,
    Journal,
}

export const useGhosts = (evidenceStatuses: EvidenceStatuses, search: SearchParams | null) => {
    const eliminatedEvidence = Object.entries(evidenceStatuses)
        .filter(([_, s]) => s === EvidenceStatus.Eliminated)
        .map(g => g[0]);

    const confirmedEvidence = Object.entries(evidenceStatuses)
        .filter(([_, s]) => s === EvidenceStatus.Confirmed)
        .map(g => g[0]);

    const [sort, setSort] = useState<GhostSort>(GhostSort.Lexicographic)

    const ghosts = useMemo(() => {
        const searchResults = search?.query ? keywordSearch(search.query) : []
        const filteredGhosts = search?.query
            ? searchResults.map(([id]) => GHOST_TYPES.find(ghostType => ghostType.journalOrder === id)!)
            : GHOST_TYPES
                .filter((ghost) => confirmedEvidence.length === 0 ? true : confirmedEvidence.every((e) => ghost.evidence.includes(e)))
                .filter((ghost) => ghost.evidence.every((e) => !eliminatedEvidence.includes(e)));

        if (search?.query) {
            // Search results are already ordered by hit frequency, so no need to re-order.
            return filteredGhosts
        }

        return filteredGhosts.sort((a, b) => sort === GhostSort.Lexicographic
            ? a.name.localeCompare(b.name)
            : a.journalOrder - b.journalOrder)

    }, [sort, eliminatedEvidence, confirmedEvidence, search]);

    const [suspected, setSuspected] = useState<GhostType | null>(null);
    const [selected, setSelected] = useState<GhostType | null>(ghosts[0]);

    return {
        ghosts,
        sort,
        setSort,
        suspected,
        setSuspected,
        selected,
        setSelected,
    }
};
