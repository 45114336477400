import React from "react";
import { DOTS_PROJECTOR, EMF_5, Evidence, FREEZING_TEMPS, GHOST_ORB, GHOST_WRITING, SPIRIT_BOX, ULTRAVIOLET } from "../Evidence";
import { Dots, Emf, Freezing, GhostOrb, GhostWriting, SpiritBox, Ultraviolet } from "./icons";

export const toIcon = (evidence: Evidence, size?: 'sm' | 'md') => {
    switch (evidence) {
        case DOTS_PROJECTOR: return <Dots size={size} />;
        case EMF_5:          return <Emf size={size} />;
        case FREEZING_TEMPS: return <Freezing size={size} />;
        case GHOST_ORB:      return <GhostOrb size={size} />;
        case GHOST_WRITING:  return <GhostWriting size={size} />;
        case SPIRIT_BOX:     return <SpiritBox size={size} />;
        case ULTRAVIOLET:    return <Ultraviolet size={size} />;
        default: throw Error(`Unknown icon for evidence type ${evidence}`)
    }
};
