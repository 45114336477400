import React from 'react';
import './App.css';
import { EvidencePanel } from './components/EvidencePanel';
import { GhostsDetails, GhostsSelector } from './components/GhostsPanel';
import { useEvidence } from './hooks/useEvidence';
import { useGhosts } from './hooks/useGhosts';
import { SearchContext, useSearch } from './hooks/useSearch';

const App = () => {
  const { evidenceStatus, flipStatus, isValid, clearEvidence } = useEvidence();
  const searchContext = useSearch();
  const { ghosts, suspected, selected, setSelected } = useGhosts(evidenceStatus, searchContext);

  return (
    <SearchContext.Provider value={searchContext}>
      <div className="pt-6 pb-4 px-4 h-full min-h-full grid grid-rows-[40px_1fr] gap-6">
        <img
          src="/phasmodeck.png"
          className="ps-4 h-10 drop-shadow-[0_0px_18px_rgba(0,255,0,0.7)]"
          alt="Phasmodeck"
          title="Phasmodeck: A less awkward ghost journal"
        />

        <div className="grid grid-cols-[250px_250px_1fr]">
          <EvidencePanel
            evidenceStatuses={evidenceStatus}
            flipStatus={flipStatus}
            isValid={isValid}
            clearEvidence={clearEvidence}
          />

          <GhostsSelector
            ghosts={ghosts}
            suspected={suspected}
            selected={selected}
            evidenceStatuses={evidenceStatus}
            onChange={setSelected}
          />

          <GhostsDetails
            ghostType={selected}
            evidenceStatuses={evidenceStatus}
          />
        </div>
      </div>
    </SearchContext.Provider>
  );
}

export default App;
