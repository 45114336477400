import { GHOST_TYPES } from './Ghost'

const StopWords = new Set([
    'it',
	'its',
	'a',
	'the',
	'for',
	'to',
	'has',
	'been',
	'with',
	'if',
	'from',
	'will',
	'also',
	'be',
	'in',
	'have',
	'of',
	'is',
	'all'
])
const Symbols = [
    ',',
    '.',
    ':',
    ';',
    '\'',
    '"',
    '?',
    '!',
    '@',
    '#',
    '\'s',
]

const clean = (line: string): string => {
    line = line.toLowerCase()
    for (const symbol of Symbols) {
        while (line.includes(symbol)) {
            line = line.replace(symbol, '')
        }
    }
    return line
}

const tokenize = (line: string): string[] => {
    const tokens: string[] = []
    clean(line).split(' ').forEach(token => {
        const t = token.trim()
        if (t && !StopWords.has(t)) {
            tokens.push(t)
        }
    })
    return tokens
}

class TrieNode {
    public readonly docIds: Array<number> = []
    public readonly chars: Map<string, TrieNode> = new Map()
}

class Trie {
    private readonly root = new TrieNode()

    public insert = (token: string, docId: number) => {
        for (let i = 0; i < token.length; i++) {
            const suffix = token.slice(i)
            let cursor = this.root
            for (const char of suffix) {
                if (!cursor.chars.has(char)) {
                    cursor.chars.set(char, new TrieNode())
                }
                cursor = cursor.chars.get(char)!
                cursor.docIds.push(docId)
            }
        }
    }

    public search = (term: string): Array<number> => {
        let cursor = this.root
        for (const char of term) {
            if (cursor.chars.has(char)) {
                cursor = cursor.chars.get(char)!
            } else {
                return []
            }
        }
        return cursor.docIds
    }
}

const buildGhostTrie = (): Trie => {
    const trie = new Trie();
    GHOST_TYPES.forEach(gt => {
        let raw = `${gt.name} ${gt.description}`
        if (gt.strength) {
            raw = `${raw} ${gt.strength}`
        }
        if (gt.weakness) {
            raw = `${raw} ${gt.weakness}`
        }
        tokenize(raw).forEach(token => trie.insert(token, gt.journalOrder))
    });
    return trie;
}

const GhostTrie = buildGhostTrie()

/* Returns DocID to Hits */
export const keywordSearch = (query: string): Array<[number, number]> => {
    const keywords = tokenize(query)
    const hits = new Map<number, number>()

    for (const keyword of keywords) {
        const results = GhostTrie.search(keyword)

        for (const key of results) {
            if (!hits.has(key)) {
                hits.set(key, 0)
            }
            hits.set(key, hits.get(key)! + 1)
        }
    }

    const results = new Array<[number, number]>()
    hits.forEach((value, key, _) => results.push([key, value]))
    results.sort((a, b) => b[1] - a[1] /* reverse */)
    return results
}
