import { createContext, useState } from "react";

export interface SearchParams {
    query: string;
    setQuery: (_ :string) => void;
}

export const useSearch = () => {
    const [query, setSearchQuery] = useState("");
    const setQuery = (query: string) => {
        setSearchQuery(query.toLowerCase());
    }
    return { query, setQuery }
};

export const SearchContext = createContext<SearchParams | null>(null);
