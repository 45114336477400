import React, { useContext, useMemo } from "react";
import { GhostType } from "../Ghost";
import { toIcon } from "./EvidenceUtil";
import classNames from "classnames";
import { EvidenceStatus, EvidenceStatuses } from "../hooks/useEvidence";
import { Circle, MagnifyingGlass, MiniCheck, MiniX } from "./icons";
import { SearchContext } from "../hooks/useSearch";

const GhostSelection: React.FC<{
    ghost: GhostType,
    suspected: boolean,
    onClick: () => void,
    evidenceStatuses: EvidenceStatuses,
}> = ({ghost, suspected, onClick, evidenceStatuses}) => {
    const confirmedEvidence = useMemo(
        () => {
            const confirmed = Object
                .entries(evidenceStatuses)
                .filter(([_, status]) => status === EvidenceStatus.Confirmed);
            return new Map(confirmed)
        },
        [evidenceStatuses]
    )

    const ghostSelectionClasses = classNames([
        suspected && "bg-[#3c3] text-green-800", // Make this better
        "flex",
        "mb-2",
        "p-2",
        "cursor-pointer",
        "justify-between",
        "border",
        "border-green-800",
        "hover:border-green-500",
        "hover:text-green-500",
        "hover:scale-105",
        "transition-transform",
        "duration-150"
    ]);

    return (
        <div className={ghostSelectionClasses} onClick={onClick}>
            {ghost.name}
            <div className="flex gap-1 text-green-800">
                {ghost.evidence.map((e, index) => (
                    <div 
                        className={classNames(
                            'mw-4',
                            confirmedEvidence.has(e) && 'text-[#3c3]',
                        )}
                        key={index}
                    >
                        {toIcon(e, 'sm')}
                    </div>
                ))}
            </div>
        </div>
    );
};

export const GhostSearch: React.FC = () => {
    const search = useContext(SearchContext);
    return (
        <div className={classNames([
            "flex",
            "mb-4",
            "p-2",
            "focus-within:text-green-500",
            "border",
            "focus-within:border-green-500",
            search?.query ? 'text-green-500 border-green-500' : "border-green-800 text-green-800"
        ])}>
            <MagnifyingGlass />
            <input
                type="search"
                placeholder="Search..."
                className="ms-2 bg-transparent w-full placeholder-green-800 outline-none uppercase"
                onChange={(e) => search?.setQuery(e.target.value)}
                value={search?.query || ""}
            />
            {search?.query && (
                <button onClick={() => search?.setQuery("")}>
                    <MiniX />
                </button>
            )}
        </div>
    );
};

export const GhostsSelector: React.FC<{
    onChange: (ghost: GhostType) => void,
    evidenceStatuses: EvidenceStatuses,
    ghosts: Array<GhostType>,
    suspected: GhostType | null,
    selected: GhostType | null,
}> = ({ghosts, suspected, onChange, evidenceStatuses}) => {
    return (
        <div className="grid grid-rows-[auto_auto_1fr]">
            <h2 className="ms-2 mb-6 drop-shadow-[0_0px_18px_rgba(0,255,0,1)] overflow-x-visible">Ghosts</h2>
            <div className="pe-4 ps-2 pt-1">
                <GhostSearch />
            </div>
            <div className="relative text-sm">
                <div className="absolute top-0 bottom-0 left-0 right-0 overflow-y-scroll pe-4 ps-2 pt-1 pb-8">
                {ghosts.map((ghostType, key) => (
                    <GhostSelection 
                        key={key}
                        ghost={ghostType}
                        suspected={ghostType.name === suspected?.name}
                        onClick={() => onChange(ghostType)}
                        evidenceStatuses={evidenceStatuses}
                    />
                ))}
                </div>
                <div className="h-8 min-h-8 absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent"></div>
            </div>
        </div>
    );
}

export const GhostsDetails: React.FC<{
    ghostType: GhostType | null,
    evidenceStatuses: EvidenceStatuses
}> = ({ghostType, evidenceStatuses}) => (
    <div className="p-2 pt-3">
        <div className="h-[2.5em]">
            {/* Toolbar. Save the space for now. */}
        </div>

        {ghostType ? (
            <div className="border border-green-800 p-2">
                <h3>{ghostType.name}</h3>
                <div className="flex flex-col gap-4 text-sm ">
                    
                    <p>{ghostType.description}</p>
                    {ghostType.strength && (
                        <div>
                            <h3>Strength</h3>
                            <p>{ghostType.strength}</p>
                        </div>
                    )}
                    {ghostType.weakness && (
                        <div>
                            <h3>Weakness</h3>
                            <p>{ghostType.weakness}</p>
                        </div>
                    )}
                    <div>
                        <h3>Evidence</h3>
                        <ul>
                            {ghostType.evidence.map((evidence, key) => (
                                <div key={key} className="flex gap-1">
                                    {evidenceStatuses[evidence] === EvidenceStatus.Confirmed ? (
                                        <MiniCheck />
                                    ) : (
                                        <span className="text-green-800">
                                            <Circle />
                                        </span>
                                    )}
                                    {evidence}
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        ) : (
            <div>No ghost selected</div>
        )}
    </div>
);
