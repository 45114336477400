import React from "react";
import { AlertBell } from "./icons";

export const Banner: React.FC<{ text: string }> = ({text}) => (
    <div className="bg-red-600 p-2 text-xs text-red-100 flex gap-2 animate-bounce">
        <div className="">
            <AlertBell size="sm" />
        </div>
        {text}
    </div>
);
