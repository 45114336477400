import React from "react";

export interface IconProps {
    size?: 'sm' | 'md';
}

const sizeToClass = (size: string = 'md') => {
    switch (size) {
        case 'sm': return 'w-4 h-4';
        case 'md': return 'w-6 h-6';
        default: throw new Error("Gah!")
    }
}

export const Emf: React.FC<IconProps> = ({size}) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={sizeToClass(size)}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
    </svg>
);

export const SpiritBox: React.FC<IconProps> = ({size}) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={sizeToClass(size)}>
        <g fill="none" fillRule="evenodd">
            <g stroke="currentColor">
                <path d="m19.54 4.5v1" strokeLinecap="round" transform="matrix(0 -1 1 0 14.54 24.54)"/>
                <path d="m14.41868 2.37868v1" strokeLinecap="round" transform="matrix(.70710678 -.70710678 .70710678 .70710678 2.187599 11.038692)"/>
                <path d="m18.66132 2.37868v1" strokeLinecap="round" transform="matrix(-.70710678 -.70710678 -.70710678 .70710678 33.8924 14.038692)"/>
                <path d="m16.54 1.5v1" strokeLinecap="round" transform="matrix(-1 0 0 1 33.08 0)"/>
                <path d="m6.5 7.5h10v14h-10z"/>
                <path d="m8.5 9.5h6v6h-6z"/>
                <path d="m16.5 4.5v17" strokeLinecap="round"/>
                <path d="m13.54 4.5v1" strokeLinecap="round" transform="matrix(0 -1 1 0 8.54 18.54)"/>
            </g>
            <circle cx="16.5" cy="5" fill="currentColor" r="1"/>
        </g>
    </svg>
);

export const GhostWriting: React.FC<IconProps> = ({size}) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={sizeToClass(size)}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
    </svg>
);

export const Dots: React.FC<IconProps> = ({size}) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={sizeToClass(size)}>
        <g fill="currentColor" fillRule="evenodd">
            <circle cx="12" cy="12" r="1"/>
            <circle cx="4" cy="12" r="1"/>
            <circle cx="20" cy="12" r="1"/>
            <circle cx="12" cy="4" r="1"/>
            <circle cx="4" cy="4" r="1"/>
            <circle cx="20" cy="4" r="1"/>
            <circle cx="12" cy="20" r="1"/>
            <circle cx="4" cy="20" r="1"/>
            <circle cx="20" cy="20" r="1"/>
        </g>
    </svg>
);

export const Ultraviolet: React.FC<IconProps> = ({size}) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={sizeToClass(size)}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
    </svg>
);

export const GhostOrb: React.FC<IconProps> = ({size}) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={sizeToClass(size)}>
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="1.5">
            <circle cx="12" cy="12" r="5.25"/>
            <path d="m12 3c-4.97056275 0-9 4.02943725-9 9m9 9c4.9705627 0 9-4.0294373 9-9" strokeLinecap="round"/>
        </g>
    </svg>
);

export const Freezing: React.FC<IconProps> = ({size}) => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={sizeToClass(size)}>
        <path d="m12 3.5v17m-2-16 2 2m2-2-2 2m-2 13 2-2m2 2-2-2m6.0104076-11.51040764-12.02081524 12.02081524m9.89949494-12.72792202v2.82842712m2.8284271 0h-2.8284271m-10.60660172 7.7781746h2.82842712m0 2.8284271v-2.8284271m12.3890873-3.8890873h-17m16-2-2 2m0 0 2 2m-15-4 2 2m0 0-2 2m13.5104076 4.0104076-12.02081524-12.02081524m12.72792204 9.89949494h-2.8284271m0 2.8284271v-2.8284271m-7.7781746-10.60660172v2.82842712m-2.82842712 0h2.82842712" fill="none" stroke="currentColor" strokeLinecap="round"/>
    </svg>
);

export const MiniCheck = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
    </svg>
);

export const MiniX = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clipRule="evenodd" />
    </svg>
);

export const AlertBell: React.FC<IconProps> = ({size}) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={sizeToClass(size)}>
        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
    </svg>
);

export const Circle = () => (
    <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" fill="none" r="7.5" stroke="currentColor"/>
    </svg>
);

export const MagnifyingGlass = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
    </svg>
);
