import { useMemo, useState } from "react";
import { DOTS_PROJECTOR, EMF_5, Evidence, FREEZING_TEMPS, GHOST_ORB, GHOST_WRITING, SPIRIT_BOX, ULTRAVIOLET } from '../Evidence';

export enum EvidenceStatus {
    Unconfirmed,
    Confirmed,
    Eliminated,
}

export type EvidenceStatuses = { [key: string]: EvidenceStatus };

export const nextStatus = (evidenceStatus: EvidenceStatus) => {
    switch (evidenceStatus) {
        case EvidenceStatus.Unconfirmed: return EvidenceStatus.Confirmed;
        case EvidenceStatus.Confirmed:   return EvidenceStatus.Eliminated;
        case EvidenceStatus.Eliminated:  return EvidenceStatus.Unconfirmed;
    }
}

const defaultStatusValues = {
    [DOTS_PROJECTOR]: EvidenceStatus.Unconfirmed,
    [EMF_5]:          EvidenceStatus.Unconfirmed,
    [FREEZING_TEMPS]: EvidenceStatus.Unconfirmed,
    [GHOST_ORB]:      EvidenceStatus.Unconfirmed,
    [GHOST_WRITING]:  EvidenceStatus.Unconfirmed,
    [SPIRIT_BOX]:     EvidenceStatus.Unconfirmed,
    [ULTRAVIOLET]:    EvidenceStatus.Unconfirmed,
};

export const useEvidence = () => {
    const [evidenceStatus, setEvidenceStatus] = useState<EvidenceStatuses>(defaultStatusValues);

    const flipStatus = (evidence: Evidence) => {
        setEvidenceStatus((prev) => ({
            ...prev,
            [evidence]: nextStatus(prev[evidence]),
        }));
    };

    const clearEvidence = () => {
        setEvidenceStatus(() => defaultStatusValues);
    };

    const isValid = useMemo(() => {
        return Object.values(evidenceStatus).filter(s => s === EvidenceStatus.Confirmed).length <= 3;
    }, [evidenceStatus]);

    return {
        evidenceStatus,
        flipStatus,
        isValid,
        clearEvidence
    };
};
