
export type Evidence = string

export const DOTS_PROJECTOR: Evidence = 'D.O.T.S. Projector';
export const EMF_5: Evidence = 'EMF Level 5';
export const FREEZING_TEMPS: Evidence = 'Freezing Temperatures';
export const GHOST_ORB: Evidence = 'Ghost Orb';
export const GHOST_WRITING: Evidence = 'Ghost Writing';
export const SPIRIT_BOX: Evidence = 'Spirit Box';
export const ULTRAVIOLET: Evidence = 'Ultraviolet';
