import React from "react";
import { Evidence } from "../Evidence";
import { toIcon } from "./EvidenceUtil";
import classNames from "classnames";
import { EvidenceStatus, EvidenceStatuses } from "../hooks/useEvidence";
import { MiniCheck, MiniX } from "./icons";
import { Banner } from "./Banner";

export const EvidenceSelector: React.FC<{
    evidence: Evidence,
    status: EvidenceStatus,
    flipStatus: (evidence: Evidence) => void,
}> = ({ evidence, status, flipStatus }) => {
    const selectorclasses = classNames(
        status === EvidenceStatus.Unconfirmed && [
            'border-green-800',
            'drop-shadow-[0_0px_4px_rgba(0,255,0,0.5)]',
        ],
        status === EvidenceStatus.Confirmed && [
            'text-[#33cc33]',
            'border-[#33cc33]',
            'drop-shadow-[0_0px_4px_rgba(0,255,0,0.5)]',
        ],
        status === EvidenceStatus.Eliminated && [
            'text-green-800',
            'border-green-800',
            'line-through'
        ], [
        "p-2",
        "pt-7",
        "text-xs",
        "text-center",
        "flex flex-col",
        "items-center",
        "border",
        "aspect-square",
        "place-content-between",
        "cursor-pointer",
        "hover:border-green-500",
        "hover:text-green-500",
        "hover:scale-105",
        "relative",
    ]);

    return (
        <div className={selectorclasses} onClick={() => flipStatus(evidence)}>
            {toIcon(evidence)}
            {evidence}
            <div className="absolute top-2 right-2">
                {status === EvidenceStatus.Confirmed && <MiniCheck />}
                {status === EvidenceStatus.Eliminated && <MiniX />}
            </div>
        </div>
    );
};

export const EvidencePanel: React.FC<{
    evidenceStatuses: EvidenceStatuses,
    flipStatus: (evidence: Evidence) => void,
    isValid: boolean,
    clearEvidence: () => void
}> = ({ evidenceStatuses, flipStatus, isValid, clearEvidence }) => {
    const someStatusDirty = Object.values(evidenceStatuses)
        .some(e => e === EvidenceStatus.Confirmed || e === EvidenceStatus.Eliminated);
    return (
        <div className="text-left px-4">
            <h2 className="mb-6 drop-shadow-[0_0px_18px_rgba(0,255,0,1)]">Evidence</h2>
            <div className="mb-4 pt-1">
                <button
                    className="block w-full p-2 border border-green-800 disabled:text-green-800 enabled:hover:border-green-500"
                    disabled={!someStatusDirty}
                    onClick={clearEvidence}
                >
                    Reset
                </button>
            </div>
            <div className="grid grid-cols-2 gap-2 pt-1 pb-6">
            {Object.entries(evidenceStatuses).map(([evidence, status], index) => (
                <EvidenceSelector
                    key={index}
                    evidence={evidence}
                    status={status}
                    flipStatus={flipStatus}
                />
            ))}
            </div>
            { !isValid && <Banner text="More than three confirmed evidence selections" />}
        </div>
    );
}
